import { NextSeo } from "next-seo"
import Image from "next/image"
import Link from "next/link"
import { HiArrowCircleLeft, HiArrowSmLeft, HiChevronLeft } from "react-icons/hi"
import { Page } from "../components/layout/page"

const NotFoundPage = () => {
    return (
        <>
            <NextSeo title="Halaman tidak ditemukan" noindex={true} />
            <Page className="page">
                <div className="flex items-center flex-col justify-center">
                    <Image
                        src="/images/404.svg"
                        objectFit="cover"
                        width={250}
                        height={250}
                        className="text-500"
                    />
                    <div className="text-3xl font-semibold">404 Error</div>
                    <div className="text-sm text-gray-500">
                        Oops! Halaman tidak ditemukan.
                    </div>
                    <div className="mt-4">
                        <Link href="/">
                            <a className="bg-brand-blue dark:bg-brand text-white px-4 py-2 rounded-md flex items-center text-lg">
                                <HiArrowCircleLeft className="mr-2" /> Kembali
                                ke halaman utama
                            </a>
                        </Link>
                    </div>
                </div>
            </Page>
        </>
    )
}

export default NotFoundPage
